export const fieldConfigs = [
  [
    {
      label: "Case Number",
      name: "case_number",
      type: "text",
      case_className: "col-md-12",
      detail_className: "col-md-12",
      validation: {
        required: true,
        errorMessage: "Case Number is required.",
      },
    },
  ],
  [
    {
      label: "Creation Date",
      name: "intial_date",
      type: "datetime-local",
      case_className: "col-md-12",
      detail_className: "col-md-12",
      validation: {
        required: true,
        errorMessage: "Creation Date is required.",
      },
    },
  ],
  [
    {
      label: "Description",
      name: "tags",
      type: "text",
      case_className: "col-md-12",
      detail_className: "col-md-12",
      validation: {
        required: false,
      },
    },
  ],
];
