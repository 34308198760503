import React, { useState, useEffect } from 'react'
import DataTable from "react-data-table-component";
import { Button, Col, Container, Row } from 'react-bootstrap'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import './evidence.css'
import Spinner from 'react-bootstrap/Spinner'
import { FaArrowAltCircleUp } from 'react-icons/fa';
import { RiDownloadLine, RiStopLine } from 'react-icons/ri';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from "react-redux";
import { AddNewCaseModal } from '../AddNewJobsModal/AddNewJobModal'
import { downloadUrl, getScheduledJob, stopJob } from "../../services/Api";
import { getJobId } from '../../../slices/getjobSlice';
import { TailSpin } from 'react-loader-spinner';
import { getJobData } from '../../services/Api';
import { BiTimeFive } from 'react-icons/bi';
import { IoMdRefresh } from 'react-icons/io'
// import { updateJobStatus } from '../../services/Api';

export const Evidence = ({ renderData, setRenderData, caseDetails }) => {
    const caseDetailsData = useSelector((state) => state.cases.caseDetailsData)
    const job_id = useSelector((state) => state.jobs.jobId)
    const loadingCondition = useSelector((state) => { return state.emails.isLoadingCondition });
    const [tableData, setTableData] = useState([]);
    const [pdf, setPdf] = useState("");
    const [zip, setZip] = useState("");
    const [png, setPng] = useState("");
    const [download, setDownload] = useState(false);
    const [downloadedRowId, setDownloadedRowId] = useState(null);
    const [startProcess, setStartProcess] = useState(true)
    const [showModal, setShowModal] = useState(false);
    const token = useSelector((state) => { return state.verifys.Token });
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const customTableStyles = {
        rows: {
            style: {
                fontFamily: 'Roboto',
            },
        },
        headCells: {
            style: {
                fontSize: "16px",
                fontWeight: 500,
            },
        },
        cells: {
            style: {
                paddingLeft: "0px",
                fontSize: "16px",
                textAlign: "left",
            },
        },
        TableCol: {
            style: {
                background: "black",
                overflowX: 'auto',
                width: "150%",
            },
        },
    };


    function funcUpdateJobStatus(e) {
        e.stopPropagation();
        getJobData(token, dispatch, setTableData, caseDetailsData)
    }

    useEffect(() => {
        getJobData(token, dispatch, setTableData, caseDetailsData)
    }, [renderData]);

    const handleRowClick = async (row, e) => {
        e.stopPropagation()
        await dispatch(getJobId(row))
        navigate("/evidenceDetail")
    };

    const downloadHandler = (event, id) => {
        event.stopPropagation();
        downloadUrl(id, token, setDownloadedRowId, setDownload, setPdf, setPng, setZip, caseDetailsData.id, setStartProcess)
    };

    const stopJobHandler = (event, id) => {
        event.stopPropagation();
        stopJob(id, token, caseDetailsData.id, setRenderData, !renderData);
      };
    
      const isStopped = (status) => {
        return status.includes("stopped");
      }

    const Tablecolumns = [
        {
            name: "ID",
            selector: (row) => (
                <>
                    <div className="evid-secondContent">
                        <div>
                            <span> {row.id}</span>
                        </div>
                    </div>
                </>
            ),
            sortable: true,
            width: "10%",
        },
        {
            name: "Job Name",
            selector: (row) => (
                <>
                    <div className="evid-secondContent">
                        <div>
                            <span> {row.job_no}</span>
                        </div>
                    </div>
                </>
            ),
            sortable: true,
            width: "15%",
        },
        {
            name: "Type",
            selector: (row) => (
                <>
                    <div className="evid-firstContent">
                        <img src='./attachment.svg' alt='attach' id='evid-common' />
                        <span className='evid-second-detail-text w-75'>{row.type}</span>
                    </div>
                </>
            ),
            sortable: true,
            width: "15%",
        },
        {
            name: "Source",
            selector: (row) => (
                <>
                    <div className="evid-firstContent">
                        <div>
                            <span> {row.url}</span>
                        </div>
                    </div>
                </>
            ),
            sortable: true,
            width: "25%",
        },
        {
            name: "Schedule",
            selector: (row) => (
                <>
                    <div className="evid-thirdContent">
                        <div>
                            <span> {row.schedule}</span>
                        </div>
                    </div>
                </>
            ),
            sortable: true,
            width: "15%",
        },
        {
            name: "Creation Date",
            selector: (row) => (
                <>
                    <div className="evid-firstContent">
                        <div className="time-icon"><BiTimeFive style={{ marginRight: '4px' }} /></div>
                        <div style={{ color: "#06152B" }}>
                            {new Date(row.createdAt).toLocaleDateString("en-US", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                            }).replace(/(\w{3}) (\d{2}), (\d{4})/, "$2 $1, $3")},{" "}
                            {new Date(row.createdAt).toLocaleTimeString("en-US", {
                                hour: "numeric",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: true
                            })}</div>
                </div>
                </>
            ),
            sortable: true,
            width: "22.5%",
        },
        {
            name: "Updated On",
            selector: (row) => (
                <>
                    <div className="evid-firstContent">
                        <div className="time-icon"><BiTimeFive style={{ marginRight: '4px' }} /></div>
                        <div style={{ color: "#06152B" }}>
                            {new Date(row.updatedAt).toLocaleDateString("en-US", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                            }).replace(/(\w{3}) (\d{2}), (\d{4})/, "$2 $1, $3")},{" "}
                            {new Date(row.updatedAt).toLocaleTimeString("en-US", {
                                hour: "numeric",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: true
                            })}</div>
                </div>
                </>
            ),
            sortable: true,
            width: "22.5%",
        },
        {
            name: "Status",
            selector: (row) => (
                <>
                    <div className='status-btn'>
                        <div>
                            {<Button className={row.status === 'Success' ? 'success' : 'created'}><span>{row.status || "Created"}</span></Button>}
                        </div>
                    </div>
                </>
            ),
            sortable: true,
            width: "12.5%",
        },
        {
            name: "Actions",
            selector: (row) => (
                <>
                <>
                    { row.schedule !== 'one-time' ? (
                    <>
                        <Button
                        disabled={isStopped(row.schedule)}
                        variant="danger"
                        type="submit"
                        className="stop-job-button"
                        onClick={(e) => stopJobHandler(e, row.id)}
                        >
                        <div className="stop-btn">
                            <div>
                            <RiStopLine />
                            </div>
                            <span className="down-write" > Stop</span>
                        </div>

                        </Button> {' '}
                    </>) : <></>
                    }
                </>
                        {downloadedRowId === row.id && download ? (startProcess) ?
                            (<Button
                                variant="dark"
                                disabled
                                className="download-success-button"
                            >
                                <div className="down-btn">
                                    <div>
                                        <Spinner animation="border" variant="light" size="sm" />
                                    </div>
                                    <span className="down-write" >Processing</span>
                                </div>

                            </Button>) :
                            (
                                <div>
                                    <div className="upbtn">
                                        <span onClick={() => setDownload(false)}><FaArrowAltCircleUp /></span>
                                    </div>
                                    <div>
                                        {pdf ? (
                                            <a style={{ color: "#52a6b6" }} className="d-block" href={pdf}>
                                                Download PDF
                                            </a>
                                        ) : (
                                            <span className="font-s">No PDF available</span>
                                        )}
                                        <br />
                                        {png ? (
                                            <a style={{ color: "#52a6b6" }} className="d-block" href={png}>
                                                Download PNG
                                            </a>
                                        ) : (
                                            <span className="font-s ">No PNG available</span>
                                        )}
                                        <br />
                                        {//zip ? (
                                         //   <a style={{ color: "#52a6b6" }} className="d-block" href={zip}>
                                         //       Download HTML
                                         //   </a>
                                        //) : (
                                        //    <span className="font-s ">No HTML available</span>
                                        //)
                                        }
                                    </div>

                                </div>
                            ) : (
                            <Button
                                disabled={!caseDetails.case_status}
                                variant="dark"
                                type="submit"
                                className="download-success-button"
                                onClick={(e) => downloadHandler(e, row.id)}
                            >
                                <div className="down-btn">
                                    <div>
                                        <RiDownloadLine />
                                    </div>
                                    <span className="down-write" > Download</span>
                                </div>

                            </Button>
                        )}
                </>
            ),
            sortable: true,
            width: "12.5%",
        },
    ];
    return (
        <>
            <Container fluid className='p-0 evidence-box'>
                <Row className='one1'>
                    <Col className='evid-col-one'>
                        <img id="evid-group" src='./Group.svg' alt='gropus' />
                        <span className='evid'>Evidence</span>
                    </Col>
                    <Col className='evid-col-two'>
                        <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 1L6.5 6L11.5 1" stroke="#5D7285" strokeWidth="1.5" />
                        </svg>
                    </Col>
                </Row>
                <Row className='two'>
                    <Col className='evid-col-three'>
                        <ButtonGroup size="lg" className="mb-2">
                            <Button className='btn-text'>
                                <img src='./search.svg' alt='search' id="evid-common" style={{ paddingBottom: '4px' }} />
                                <span>Explore</span></Button>
                            <Button className='btn-text'>
                                <img src='./Layer_1.svg' alt='layer' id='evid-common' />
                                <span>Movement</span></Button>
                            <Button className='btn-text'>
                                <img src='./Union.svg' alt='union' id='evid-common' />
                                <span>Log</span></Button>
                        </ButtonGroup>
                    </Col>
                    <Col className='evid-col-four'>
                        <Button className='add2'>
                            <div>
                                <IoMdRefresh id='refresh' onClick={(e) => funcUpdateJobStatus(e)} />
                            </div>
                        </Button>
                        <Button className='add1' disabled={!caseDetails.case_status}
                            onClick={() => setShowModal(true)}>
                            <img src='./plus.svg' alt='plus' id='evid-common' />
                            <span>Add item</span></Button>
                    </Col>
                </Row>
                <Row className='evid-tableCaseContent'>
                    <DataTable
                        columns={Tablecolumns}
                        data={tableData}
                        onRowClicked={handleRowClick}
                        customStyles={customTableStyles}
                        scrollX
                        pagination
                        highlightOnHover
                    />
                </Row>
                <Row>
                    <AddNewCaseModal
                        showModal={showModal}
                        setShowModal={setShowModal}
                        setRenderData={setRenderData}
                        renderData={renderData} />
                </Row>
                {loadingCondition && (
                    <TailSpin
                        height="50"
                        width="50"
                        radius="5"
                        color="black"
                        ariaLabel="loading"
                        wrapperStyle={{ position: "absolute", top: "60%", left: "50%" }} />
                )}
            </Container>
        </>
    )
}
