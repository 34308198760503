import React, { useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UserContext } from "../../App";
import { emailGet } from "../../slices/loginSlice";
import { callLoginWogAad } from "../services/Api";
import { verifyGet } from "../../slices/verifySlice";

const CallbackPage = () => {
    const { email, setemail, ip, setTokenFromVerify } = useContext(UserContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const handleCallback = () => {
            const hashFragment = window.location.hash.substring(1);
            const urlParams = new URLSearchParams(hashFragment);
            const idToken = urlParams.get('id_token');
            callLoginWogAad(dispatch, idToken, setemail, navigate, emailGet, verifyGet, setTokenFromVerify);
        };
        handleCallback();
    }, []);

    return (
        <div>
            <p></p>
        </div>
    );
};

export default CallbackPage;
